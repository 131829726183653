import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import Prediction from './Prediction/Prediction';
import Playoffs from './Playoffs/Playoffs';
import './History.css';

export default function History() {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/playoffs/:username`}>
                <Playoffs />
            </Route>
            <Route path={`${match.path}/:username`}>
                <UserHistory />
            </Route>
            <Route path={match.path}>
                <h3>Please select a user.</h3>
            </Route>
        </Switch>
    );
}

function UserHistory() {
    let { username } = useParams();

    const [user, setUser] = useState(undefined);
    const [predictions, setPredictions] = useState(undefined);
    const [games, setGames] = useState(undefined);

    function checkUser(userObject) {
        return userObject.username == username;
    }

    function getGame(matchId) {
        if (games === undefined) {
            return 'Games not loaded...';
        }

        var game = games.filter((g) => {
            return g.match_id == matchId;
        })['0'];

        console.log(game);

        return game;
    }

    useEffect(() => {
        fetchData().then(response => {
            setUser(response.filter(checkUser)['0']);
            setPredictions(response.filter(checkUser)['0']['predictions']);
        })
    }, []);

    useEffect(() => {
        fetchGameData().then(response => {
            setGames(response);
            getGame('0');
        })
    }, []);

    const fetchData = async () => {
        const res = await fetch('https://tgp3t1.deta.dev/users');
        const json = await res.json();
        return json;
    }

    const fetchGameData = async () => {
        const res = await fetch('https://tgp3t1.deta.dev/games');
        const json = await res.json();
        return json;
    }

    function Predictions() {
        if (predictions !== undefined && games !== undefined) {
            return Object.keys(predictions).map((key, index) => (
                //<p key={index}> Match {key}: {predictions[key]['winner'].toUpperCase()} {predictions[key]['home_goals']} - {predictions[key]['away_goals']}</p>
                <Prediction key={index} prediction={predictions[key]} game={getGame(predictions[key]['match_id'])} />
            ));
        } else {
            return <p>No predictions loaded!</p>
        }
    }

    return (
        <div className="col d-col justify-content-center">
            <div className="row d-flex justify-content-center History-playoffs">
                <Link to={`/history/playoffs/${username}`} className="playoffButton">SLUTSPEL</Link>
            </div>
            <div className="row d-flex justify-content-center">
                <h3>{username}, totalt: {user != undefined ? user['alltime'] + user['bonus'] : ''}p</h3>
            </div>
            <div className="row d-flex justify-content-center">
                <Predictions className="predictions" />
            </div>
            <div className="row d-flex justify-content-center">
                <Link to="/" className="backButton">TILLBAKA</Link>
            </div>
        </div>
    );
}