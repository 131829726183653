import React, { useState, useEffect } from 'react';
import './Tracker.css';

function Tracker() {
    const [userAmount, setUserAmount] = useState(0);

    useEffect(() => {
        fetchData().then(response => {
          setUserAmount(response.length);
        })
    });

    const fetchData = async () => {
        const res = await fetch('https://tgp3t1.deta.dev/users')
        const json = await res.json()
        return json
    }

    return (
        <div className="Tracker-container">
            <p>Totalt: <b>{userAmount*150}</b> kronor <br />Vinst: <b>{userAmount*150*0.75}</b> kronor <br />Antal Fadøl till resten: <b>{Math.floor((userAmount*150*0.25)/9.90)}</b> st<br />Alternativt: <b>{((userAmount*150*0.25)/308.57).toFixed(2)}</b> liter Explorer</p>
        </div>
    );
}

export default Tracker;