import React, { Component } from 'react';

import TableRow from '../TableRow/TableRow';
import ToSortHeader from '../ToSortHeader/ToSortHeader';
import './Table.css';

const isTesting = false;
const url = isTesting ? 'http://127.0.0.1:5000' : 'https://tgp3t1.deta.dev';

const sortByMapping = [
  { type: 'allTime', heading: 'Poäng' },
];

class Table extends Component {
  constructor() {
    super();

    this.state = {
      defaultData: [],
      allTimeData: [],
      allTimeDataDesc: false,
    };
  }

  urlGenerator(slug) {
    return url + slug;
  }

  componentDidMount() {
    this.fetchData(this.urlGenerator('/users'));
  }

  fetchData(url) {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          allTimeData: data,
        });
        this.sortAllTimeData();
      })
      .catch((err) => console.log('err::', err))
  }

  handleSort = (sortType) => {
    if (sortType === 'allTime') {
      this.sortAllTimeData();
    }
  }

  sortAllTimeData() {
    const { allTimeData, allTimeDataDesc } = this.state;

    const sortedAllTimeData = allTimeDataDesc ? allTimeData.sort((a, b) => (a.alltime + a.bonus) - (b.alltime + b.bonus)) : allTimeData.sort((a, b) => (b.alltime + b.bonus) - (a.alltime + a.bonus));
    this.setState({
      defaultData: sortedAllTimeData,
      allTimeDataDesc: !this.state.allTimeDataDesc,
    });
  }

  render() {
    const rows = this.state.allTimeData.map((rowData, i) => {
      return <TableRow key={i} id={i + 1} rowData={rowData} />
    });

    const sorters = sortByMapping.map((toSortBy, i) => {
      return <ToSortHeader key={i} sortBy={toSortBy.type} heading={toSortBy.heading} onClick={this.handleSort} />
    });

    return (
      <table>
        <tbody>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Användarnamn</th>
            {sorters}
          </tr>
          {rows}
        </tbody>
      </table>
    )
  }
}

export default Table;