import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

import './TableRow.css';

const TableRow = (props) => {
  const {alltime, img, username, bonus, url} = props.rowData;

  return (
    <tr>
      <td>{props.id}</td>
      <td align="left">
        <Link to={"/history/" + username} >
          <img className="camper-image" src={img} alt="no img"></img>
          <span className="camper-name">{username}</span>
        </Link>
      </td>
      <td>{alltime + bonus}</td>
    </tr>
  )
}

TableRow.propTypes = {
  id: PropTypes.number,
  rowData: PropTypes.object,
};

export default TableRow;
