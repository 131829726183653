import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import logo from '../../gran.png';
import './App.css';

import Table from '../Table/Table';
import Tracker from '../Tracker/Tracker';
import History from '../History/History';

export default function App() {
  //render() {
  return (
    <Router>
      <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <Switch>
        <Route path="/history">
          <History />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <footer className="App-footer">
        <a href="https://goo.gl/maps/Xf6QjhiwVD6MLG8DA" target="_blank">Färjevägen 22</a>
      </footer></div>
    </Router>
  );
  //}
}

function Home() {
  return (
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8">
            <div className="main-header">
              Topplista
              </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8">
            <div className="overflow-table">
              <Table />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="App-tracker-container col-lg-5">
            <Tracker />
          </div>
        </div>
      </div>
  );
}

//export default App;
