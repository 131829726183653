import React, { useState, useEffect } from 'react';
import {
    useParams
} from "react-router-dom";
import './Playoffs.css';

export default function Playoffs() {
    let { username } = useParams();

    const [user, setUser] = useState(undefined);
    const [playoffs, setPlayoffs] = useState(undefined);

    useEffect(() => {
        fetchData().then(response => {
            setUser(response.filter(checkUser)['0']);
        })
    }, []);

    useEffect(() => {
        fetchPlayoffsData().then(response => {
            setPlayoffs(response);
        })
    }, []);

    function checkUser(userObject) {
        return userObject.username == username;
    }

    const fetchData = async () => {
        const res = await fetch('https://tgp3t1.deta.dev/users');
        const json = await res.json();
        return json;
    }

    const fetchPlayoffsData = async () => {
        const res = await fetch('https://tgp3t1.deta.dev/playoffs');
        const json = await res.json();
        return json;
    }

    if (user == null) {
        return <h2>Loading...</h2>;
    }

    function Quarters() {
        const quarterTeams = playoffs.filter(item => item.round === 'quarters')[0].teams;

        const listItems = user.playoffs['quarters'].map((team) => {
            if (quarterTeams.includes(team)) {
                return (
                    <div key={`${team}${Math.random() * 10000}`} className="Playoffs-prediction-true">
                        {team} <span>+2</span>
                    </div>
                )
            }

            return (
                <div className="Playoffs-prediction-false">
                    {team}
                </div>
            )
        });

        return listItems;
    }

    function Semis() {
        const semiTeams = playoffs.filter(item => item.round === 'semis')[0].teams;

        const listItems = user.playoffs['semis'].map((team) => {
            if (semiTeams.includes(team)) {
                return (
                    <div key={`${team}${Math.random() * 10000}`} className="Playoffs-prediction-true">
                        {team} <span>+3</span>
                    </div>
                )
            }

            return (
                <div className="Playoffs-prediction-false">
                    {team}
                </div>
            )
        });

        return listItems;
    }

    function Finals() {
        const finalTeams = playoffs.filter(item => item.round === 'finals')[0].teams;

        const listItems = user.playoffs['finals'].map((team) => {
            if (finalTeams.includes(team)) {
                return (
                    <div key={`${team}${Math.random() * 10000}`} className="Playoffs-prediction-true">
                        {team} <span>+4</span>
                    </div>
                )
            }

            return (
                <div className="Playoffs-prediction-false">
                    {team}
                </div>
            )
        });

        return listItems;
    }

    function Winner() {
        if (playoffs.filter(item => item.round === 'winner')[0].teams[0] == user.playoffs['winner']) {
            return (
                <div className="Playoffs-prediction-true">
                    {user.playoffs['winner']} <span>+5</span>
                </div>
            )
        }

        return (
            <div className="Playoffs-prediction-false">
                {user.playoffs['winner']}
            </div>
        )
    }

    return (
        <div className="Playoffs-div w-80">
            <div className="Playoffs-title">
                <p>Slutspel för {username}</p>
            </div>
            <div className="Playoffs-quarters">
                <h2>Kvartsfinalister (+2)</h2>
                <Quarters />
            </div>
            <div className="Playoffs-semis">
                <h2>Semifinalister (+3)</h2>
                <Semis />
            </div>
            <div className="Playoffs-finals">
                <h2>Finalister (+4)</h2>
                <Finals />
            </div>
            <div className="Playoffs-finals">
                <h2>Mästare (+5)</h2>
                <Winner />
            </div>
        </div>
    );
}