import React, { useState, useEffect } from 'react';
import './Prediction.css';

export default function Prediction(props) {
    const prediction = props.prediction;
    const game = props.game;

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function getPredictionStyle() {
        if (game['played'] == true) {
            if (correctWinner()) {
                return { color: 'white', 'background-color': 'green' };
            }
            return { color: 'white', 'background-color': 'red' };
        } else {
            return { 'background-color': 'grey' };
        }
    }

    function CalculatedScore() {
        var totalScore = 0;

        if (correctScore() && game['played'] == true) {
            totalScore += 1;
        }
        if (correctWinner()) {
            totalScore += 2;
        }

        if (totalScore != 0) {
            return <span style={{'font-weight': 'bold'}}> +{totalScore}p</span>
        }

        return <span />;
    }

    function Result() {
        if (game['played'] == false) {
            return ''
        }

        return <span>{game['home_goals']}-{game['away_goals']}</span>
    }

    function correctWinner() {
        if (game['winner'].toLowerCase() == prediction['winner'].toLowerCase()) {
            return true;
        }
        return false;
    }

    function correctScore() {
        if (game['home_goals'] == prediction['home_goals'] && game['away_goals'] == prediction['away_goals']) {
            return true;
        }
        return false;
    }

    return (
        <div className="predictionContainer row justify-content-center" style={getPredictionStyle()}>
            <p className="prediction">{game['home_team']} vs {game['away_team']} <Result />
            <br />Prediction: {prediction['home_goals']}-{prediction['away_goals']}<CalculatedScore /></p>
        </div>
    );
}